@media only screen and (min-width: 300px) {
  .mobile-layover {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    background-color: gray;
    padding: 0 2rem;

    z-index: 1;
  }

  .mobile-layover h3 {
    text-align: center;
  }
}

@media only screen and (min-width: 768px) {
  .mobile-layover {
    display: none;
  }
}

#BCG div {
  font-family: 'Mark Pro', sans-serif;
}

#BCG .object-top,
#BCG .object-bottom,
#BCG .empty {
  background-color: #ebebeb;
  display: block;
  position: relative;
  padding-top: 2rem;
}
#BCG .object-top.big,
#BCG .object-bottom.big {
  padding-top: 4rem;
}
#BCG .object-top::before,
#BCG .object-bottom::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 4rem;
  right: 4rem;
  bottom: 0;
  border-left: 1px solid white;
  border-right: 1px solid white;
}
#BCG .object-top::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 1px solid white;
}
#BCG .object-bottom::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 1px solid white;
}
#BCG .content {
  display: block;
  position: relative;
  padding: 0 4rem;
  background-color: #ebebeb;
}
#BCG .sub-content {
  /* padding: 20px; */
  border-bottom: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
}
#BCG .sub-content:last-of-type {
  border-bottom: none;
}
#BCG .sub-content [class^="col"] {
  border-right: 1px solid white;
  padding: 0;
}
#BCG .sub-content [class^="col"]:last-of-type {
  border-right: none;
}
#BCG .sub-content [class^="col"]:nth-of-type(3n) {
  border-right: none;
}
#BCG .sub-content [class^="col"]:nth-of-type(n + 4) {
  border-top: 1px solid white;
}
#BCG .dropdown-panel {
  display: block;
  position: relative;
  padding: 1rem;
  transition: background-color 300ms ease;
  cursor: pointer;
}
#BCG .dropdown-panel::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 4px;
  background-color: #008caa;
  opacity: 0;
  transition: opacity 300ms ease;
}
#BCG .dropdown-panel.active {
  /* background-color: #dfdfdf; */
  background-color: #282828;
}
#BCG .dropdown-panel.active > .title {
  /* color: #008caa; */
  color: white;
}
#BCG .dropdown-panel.active > .category {
  color: #dfdfdf;
}
#BCG .dropdown-panel.active > .title:before {
  background-color: white;
}
#BCG .dropdown-panel.active:hover > .title:before {
  background-color: #282828;
}
#BCG .dropdown-panel.active:hover > .category {
  color: gray;
}
#BCG .dropdown-panel:hover {
  background-color: #dfdfdf;
}
#BCG .dropdown-panel:hover::after {
  opacity: 1;
}
#BCG .dropdown-panel:hover > .title,
#BCG .job-card:hover > .title {
  color: #008caa;
}
#BCG .job-card.active > .title {
  color: white;
}
#BCG .dropdown-panel > .category {
  text-transform: uppercase;
  color: gray;
  font-size: 0.75rem;
}
#BCG .section-title,
#BCG .dropdown-panel > .title,
#BCG .dropdown-panel.active:hover > .title {
  /* font-weight: bold; */
  position: relative;
  color: #464646;
  font-size: 1.25rem;
  transition: color 300ms ease;
}
#BCG .section-title {
  font-size: 17px;
  padding: 2rem 5rem 1rem;
  text-transform: uppercase;
}
#BCG .title-message {
  padding: 1rem;
}
#BCG .dropdown-panel > .title::before {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  background-color: #282828;
  -webkit-mask-image: url(icons/chevron-down-solid.svg);
  mask-image: url(icons/chevron-down-solid.svg);
  mask-repeat: no-repeat;
  mask-size: 1.5rem 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
}
#BCG .dropdown-panel > .title.active::before {
  -webkit-mask-image: url(icons/chevron-up-solid.svg);
  mask-image: url(icons/chevron-up-solid.svg);
}
#BCG .dropdown-panel > .dropdown-list {
  display: block;
  position: absolute;
  max-height: 20rem;
  overflow-y: scroll;
  left: 0;
  right: 0;
  top: 100%;
  background: white;
  z-index: 1000;
  /* border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf; */
  outline: 1px solid #dfdfdf;
}
#BCG .dropdown-panel > .dropdown-list > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#BCG .dropdown-panel > .dropdown-list > ul > li {
  padding: 0.5rem 1rem;
  color: #464646;
}
#BCG .dropdown-panel > .dropdown-list > ul > li:hover {
  color: white;
  background-color: #464646;
}
#BCG .dropdown-panel > .dropdown-list > ul > li:active {
  background-color: #008caa;
}
#BCG .media-card,
#BCG .job-card {
  display: block;
  position: relative;
  padding: 1rem;
  transition: background-color 300ms ease;
}
#BCG #job-list-panel {
  height: 600px;
  overflow-y: overlay;
}
#BCG #job-list-panel [class^="col"] {
  border: none;
  border-bottom: 1px solid white;
}
#BCG #job-description-panel {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  height: 600px;
}
#BCG #job-description-panel-top,
#BCG #job-description-panel-bottom {
  padding: 1rem;
}
#BCG #job-description-panel-top {
  border-bottom: 1px solid #ebebeb;
  flex: 0 0 auto;
}
#BCG #job-description-panel-bottom {
  padding: 2rem 1rem 3rem;
  flex: 0 1 auto;
  overflow-y: scroll;
}
#BCG #job-list-panel::-webkit-scrollbar,
#BCG #job-description-panel-bottom::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}
#BCG #job-list-panel::-webkit-scrollbar-thumb,
#BCG #job-description-panel-bottom::-webkit-scrollbar-thumb {
  background-color: rgba(70, 70, 70, 0.25);
}
#BCG .media-card > .description {
  margin-bottom: 0;
  font-size: 0.875rem;
}
#BCG .media-card > .time-stamp,
#BCG #job-description-panel .team,
#BCG #job-description-panel .location {
  margin-top: 1rem;
  margin-bottom: 0;
  color: gray;
  font-size: 0.875rem;
}
#BCG .job-card:hover {
  background-color: white;
  cursor: pointer;
}
#BCG .job-card.active {
  /* background-color: #dfdfdf; */
  background-color: #464646;
  cursor: initial;
}
#BCG .job-card > .tag {
  padding: 4px 8px;
  margin-right: 8px;
  font-size: 0.75rem;
  color: #464646;
  background-color: white;
  transition: background-color 300ms, color 300ms ease;
  cursor: pointer;
}
#BCG .job-card:hover > .tag {
  background-color: #dfdfdf;
}
#BCG .job-card.active > .tag {
  background-color: white;
}
#BCG .job-card > .tag:hover {
  color: #008caa;
}
#BCG .job-card > .tag:active {
  transition: none;
  /* background-color: #282828; */
  background-color: #dfdfdf;
  /* color: white; */
  color: #464646;
}
#BCG .job-card > .title {
  font-size: 1.125rem;
  /* font-weight: bold; */
  margin-bottom: 0.5rem;
  /* min-height: 3.75rem; */
  transition: color 300ms ease;
}
#BCG #job-description-panel .title {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}
#BCG #job-description-panel .description {
  margin: 0;
  font-size: 0.875rem;
  white-space: pre-line;
}
#BCG .columnCollapse {
  padding: 0 !important;
}
#BCG .expandable {
  background-color: inherit;
  transition: background-color 300ms ease;
}
#BCG .expandable:hover {
  background-color: #dfdfdf;
  cursor: pointer;
}
#BCG .button {
  margin-top: 1rem;
  padding: 0.25rem 0.75rem;
  background-color: #282828;
  color: white;
  border: none;
  cursor: pointer;
  /* font-weight: bold; */
  transition: background-color 300ms, color 300ms ease;
  text-transform: uppercase;
}
#BCG .button:active {
  transition: none;
  background-color: #dfdfdf;
  color: #464646;
}
#BCG .button:focus {
  outline: none;
}
#BCG .logo {
  float: right;
  height: 75px;
  max-width: 125px;
}