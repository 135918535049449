/* // Small devices (landscape phones, 300px and up) */
@media (min-width: 300px) { 
  .accordion__panel__body.description {
    background-color: #ffffff;
    padding: 1rem;
  }
  
  .accordion__panel__body.description .description-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .accordion__panel__body.description .description-list li {
    position: relative;
    background-color: #dfe3ee;
    border: 1px solid #8b9dc3;
    border-bottom: none;
    color: #3b5998;
    padding: 1rem 2rem;
  }
  
  .accordion__panel__body.description .description-list li::before {
    font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f0da";
    position: absolute;
    top: 1.0625rem;
    left: 1rem;
  }
  
  .accordion__panel__body.description .description-list li:last-of-type {
    border-bottom: 1px solid #8b9dc3;
  }
  
  .accordion__panel__body.description .skill-list {
    display: inline-block;
    list-style: none;
    margin: 0 0 1rem 0;
    padding: 0;
  }
  
  .accordion__panel__body.description .skill-list li {
    position: relative;
    display: inline-block;
    background-color: #3b5998;
    border: 1px solid #3b5998;
    color: #ffffff;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
  }
  
  .accordion__panel__body.description .category-title {
    display: inline-block;
    background-color: #ffffff;
    border: 4px solid #3b5998;
    color: #3b5998;
    font-weight: bold;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .accordion__panel__body.description .skill-list li::before {
    content: '';
    position: absolute;
    top: 50%;
    right: calc(100% + 1px);
    width: 1rem;
    height: 2px;
    background-color: #3b5998;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  .accordion__panel__body.description {
    background-color: #ffffff;
    padding: 1.5rem 6rem 1.5rem 4rem;
  }

  .accordion__panel__body.description .category-title {
    margin-bottom: 0;
  }

  .accordion__panel__body.description .skill-list li {
    margin-bottom: 0;
  }
}