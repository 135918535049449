/* // Small devices (landscape phones, 300px and up) */
@media (min-width: 300px) { 
  #skills {
    background-image: url('../../../images/bg.png');
  }
  
  .skill-category__container {
    margin: 0 1rem 1rem;
  }
  
  .skill-category__container__title {
    display: inline-block;
    background-color: #3b5998;
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 0.5rem 1rem;
  }
  
  .skill-category__container__content {
    padding: 1rem 0;
  }
  
  .skill-category__container__content .skill-card {
    position: relative;
    display: inline-block;
    background-color: #ffffff;
    border: 1px solid #e7e7e7;
    border-radius: 0.25rem;
    box-shadow: 0 3px 0 rgba(0,0,0,0.1);
    margin-bottom: 1rem;
    padding: 0.5rem;
    margin-right: 0.5rem;
    width: 100%;
  }

  .skill-category__container__content .skill-card::before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
  
  .skill-category__container__content .skill-card:last-of-type {
    margin-right: 0;
  }
  
  .skill-card .skill-card__image__container {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    left: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f7f7f7;
    border: 1px solid #e7e7e7;
  }

  .skill-card .skill-card__image__container::before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
  
  .skill-card .skill-card__image__container__icon {
    font-size: 5rem;
  }
  
  .skill-card .skill-card__label {
    color: #7b7b7b;
    font-weight: bold;
    margin: 0.5rem 0 0;
    text-align: center;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .skill-category__container {
    margin: 0 2rem 2rem 2rem;
  }

  .skill-category__container__content {
    padding: 1rem;
  }

  .skill-category__container__content .skill-card {
    margin-right: 1rem;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .skill-category__container {
    margin: 0 6rem 1rem 4rem;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
}