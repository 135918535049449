/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 275px) { 
  .craigslist {
    background: #f9f9f9;
    padding-top: 2rem;
  }
  
  .craigslist .nopadding {
    padding: 0;
  }
  
  .craigslist .left-bar h5 {
    background-color: #007bff;
    color: #fdfdfd;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    margin: 0 auto 1rem;
    padding: 0.75rem;
    transition: background-color 200ms ease;
  }
  
  .craigslist .left-bar h5:hover {
    background-color: #0072eb;
  }
  
  .craigslist .react-calendar {
    background-color: #fdfdfd;
    border: 1px solid #f0f0f0;
    font-family: inherit;
    margin: 2rem auto 2rem;
  }
  
  .craigslist .react-calendar * {
    font-size: 0.875rem;
  }
  
  .craigslist .react-calendar .react-calendar__month-view__weekdays__weekday abbr {
    font-size: 0.5rem;
    text-decoration: underline;
  }
  
  .craigslist .react-calendar button:focus {
    outline: none;
  }
  
  .craigslist .react-calendar__tile--active {
    background-color: #007bff;
  }
  
  .craigslist footer {
    padding: 1rem 0;
    background-color: #f4f4f4;
    font-size: 0.875rem;
    text-align: center;
  }
  
  .craigslist .clfooter {
    margin: 0;
    padding: 0;
  }
  
  .craigslist .clfooter li{
    display: inline-block;
    margin: 0 0.5rem;
  }
  
  sup {
    color: orange;
  }
  
  .craigslist .error-message {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
  
    padding: 0.5rem 1rem;
  
    color: #fdfdfd;
    background-color: #ff007b;
    font-size: 1rem;
    text-align: center;
    z-index: 2;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .craigslist .react-calendar {
    width: 500px;
  }

  .craigslist footer {
    margin-top: 2rem;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .craigslist .react-calendar {
    width: initial;
    margin: 1rem auto 0;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  
}