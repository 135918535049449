/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 275px) { 
  #user_resources,
  #about_craigslist {
    font-size: 0.875rem;
    list-style: none;
    margin-bottom: 1rem;
    padding: 0;
  }
  
  #user_resources {
    margin-top: 2rem;
  }
  
  #user_resources li,
  #about_craigslist li {
    border: 1px solid #f0f0f0;
    text-align: center;
    background-color: #fdfdfd;
    transition: background-color 200ms ease;
  }
  
  #user_resources li:hover,
  #about_craigslist li:hover {
    background-color: #f4f4f4;
  }
  
  #user_resources li a,
  #about_craigslist li a {
    display: block;
    padding: 0.5rem 0;
  
    transition: color 200ms ease;
  }
  
  #user_resources li a:hover,
  #about_craigslist li a:hover {
    color: #551a8b;
    text-decoration: none;
  }
  
  #user_resources li:not(:last-child),
  #about_craigslist li:not(:last-child) {
    border-bottom: none;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  #user_resources,
  #about_craigslist {
    width: 20rem;
    font-size: 0.875rem;
    list-style: none;
    margin: 2rem auto 0;
    padding: 0;
  }
  
  #about_craigslist {
    margin: 2rem 0 2rem;
  }

  #user_resources {
    float: left;
  }

  #about_craigslist {
    float: right;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  #user_resources,
  #about_craigslist {
    float: none;
    width: initial;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  
}