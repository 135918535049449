/* // Small devices (landscape phones, 300px and up) */
@media (min-width: 300px) { 
  #main-container {
    margin-left: 0;
    min-height: 100vh;
    background-color: #f7f7f7;
  
    margin-bottom: 4rem;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  #main-container {
    margin-left: 6rem;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  
}