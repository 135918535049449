/* // Small devices (landscape phones, 300px and up) */
@media (min-width: 300px) { 
  #overview {
    background-color: #ffffff;
    padding-bottom: 0;
  }
  
  #overview .section-container {
    display: flex;
    align-items: center;
    position: relative;
    height: 100vh;
  }
  
  #overview h1 {
    color: #3b5998;
    display: inline-block;
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 3rem;
    /* margin: 6rem 1rem 1rem; */
    margin-left: 1rem;
  }
  
  #overview h1 .highlight {
    background-color: #3b5998;
    color: #ffffff;
    padding: 0.25rem 1rem;
  }
  
  #overview .box {
    position: absolute;
    /* right: 0; */
    background-color: #3b5998;
    z-index: 1;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 

  #overview h1 {
    font-size: 3rem;
    line-height: 5rem;
    /* margin: 3rem 0 0 3rem; */
    margin-left: 3rem;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  #overview h1 {
    font-size: 3rem;
    line-height: 5rem;
    /* margin: 3rem 0 0 3rem; */
    width: 49rem;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  
}