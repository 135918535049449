@import url('https://use.fontawesome.com/releases/v5.7.1/css/all.css');

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Mark Pro';
  src: url('fonts/Mark\ Pro/MarkPro.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'Mark Pro';
  src: url('fonts/Mark\ Pro/MarkPro-Bold.otf');
  font-weight: 700;
}

@font-face {
  font-family: 'Mark Pro';
  src: url('fonts/Mark\ Pro/MarkPro-Black.otf');
  font-weight: 900;
}

/* // Small devices (landscape phones, 300px and up) */
@media (min-width: 300px) { 

}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 

}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  
}

.custom-display-1 {
  font-size: 3rem;
  font-weight: 500;
}

.custom-display-2 {
  font-size: 2rem;
  font-weight: 700;
}

.custom-display-3 {
  font-size: 1.5rem;
  font-weight: 700;
}

.custom-display-4 {
  font-size: 1rem;
  font-weight: 700;
}