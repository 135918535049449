/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 275px) { 
  .top-header {
    margin-bottom: 1rem;
    /* text-align: center */
  }
  
  .top-header .logo {
    color: #551a8b;
    font-family: 'Times New Roman', Times, serif;
    font-size: 2.5rem;
    font-weight: 500;
    float: left;
  }
  
  .top-header #my-account {
    float: right;
  }
  
  .top-header #my-account h5 {
    color: #007bff;
    cursor: pointer;
    font-weight: bold;
    padding: 0.75rem;
    transition: color 200ms ease;
  }
  
  .top-header #my-account h5:hover {
    color: #551a8b;
  }
  
  .search-bar {
    float: left;
    position: relative;
    height: 3.75rem;
    width: 100%;
    background-color: #fdfdfd;
    border: 1px solid #f0f0f0;
    margin-bottom: 0;
    padding: 1rem;
  
    transition: all 200ms ease;
  }
  
  .search-bar .label {
    position: absolute;
    bottom: 0.875rem;
    left: 1rem;
    font-size: 1rem;
    color: #9098A9;
    transform-origin: 0 0;
    transition: all .2s ease;
  }
  
  .search-bar .borderline {
    position: absolute;
    bottom: 0.5rem;
    left: 1rem;
    height: 2px;
    width: calc(100% - 2rem);
    background-color: #007bff;
    transform: scaleX(0);
    transform-origin: 0 0;
    transition: all 200ms ease;
  }
  
  .search-bar input {
    position: absolute;
    left: 1rem;
    bottom: 0.5rem;
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid #f0f0f0;
  
    color: #223254;
  
    font-size: 1rem;
    line-height: 1.875rem;
  
    height: calc(2rem - 2px);
    width: calc(100% - 2rem);
    transition: all 200ms ease;
  }
  
  /* .search-bar:hover {
    background-color: #f4f4f4;
  } */
  
  .search-bar input:not(:placeholder-shown) ~ .label {
    color: #5A667F;
    transform: translateY(-1rem) scale(0.75);
  }
  
  .search-bar input:focus {
    outline: none;
  }
  
  .search-bar input:focus ~ .label {
    color: #007bff;
    transform: translateY(-1rem) scale(0.75);
  }
  
  .search-bar input:focus ~ .borderline {
    transform: scaleX(1);
  }
  
  .search-bar input:not(:placeholder-shown) ~ .label {
    color: #007bff;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .search-bar {
    width: calc(100% - 15rem);
    margin-bottom: 1rem;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 

}