/* // Small devices (landscape phones, 300px and up) */
@media (min-width: 300px) { 
  #portfolio .section-content {
    line-height: 0;
  }

  .key-container {
    padding: 0 4rem 2rem 4rem;
  }
  
  .key-container ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .key-container li {
    position: relative;
    display: inline-block;
    font-weight: 500;
    padding: 1rem 2rem 1rem 4rem;
    margin-right: 1rem;
    border-radius: 0.25rem;
  }
  
  .key-container li.work {
    background-color: #fad15f;
    border: 1px solid #f48a35;
    color: #f48a35;
  }
  
  .key-container li.work::before {
    font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f1ad";
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 4rem;
    color: #f48a35;
  }
  
  .key-container li.personal {
    background-color: #aed8fb;
    border: 1px solid #359ff4;
    color: #359ff4;
  }
  
  .key-container li.personal::before {
    font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f007";
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 4rem;
    color: #359ff4;
  }
  
  /* .portfolio-container__box {
    position: relative;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #e7e7e7;
    cursor: pointer;
    margin: 0 0 1rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
    transition: all 200ms ease;
  }
  
  .portfolio-container__box.work::before,
  .portfolio-container__box.personal::before {
    font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f1ad";
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1rem;
    right: 1rem;
    height: 2.5rem;
    width: 2.5rem;
    background-color: #fad15f;
    border-radius: 100%;
    color: #f48a35;
  }
  
  .portfolio-container__box.personal::before {
    content: "\f007";
    background-color: #aed8fb;
    color: #359ff4;
  }
  
  .portfolio-container__box:hover {
    transform: translateY(-0.25rem);
    box-shadow: 0 4px 0 rgba(223,227,238,0.6);
  }
  
  .portfolio-container__box .portfolio-container__box__image {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
    border-radius: 0.25rem;
    border: 1px solid #e7e7e7;
  }
  
  .portfolio-container__box .portfolio-container__box__content {
    background-color: #ffffff;
    margin-top: 0.5rem;
    text-align: center;
  }
  
  .portfolio-container__box .portfolio-container__box__content .title {
    background-color: #dfe3ee;
    border: 1px solid #3b5998;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #3b5998;
    padding: 0.75rem 1.5rem;
  
    transition: all 200ms ease;
  }
  
  .portfolio-container__box:hover .portfolio-container__box__content .title {
    background-color: #3b5998;
    color: #ffffff;
  } */

  .borderless-container {
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 100%
  }

  .borderless-container:hover .borderless-container__image {
    filter: brightness(100%);
  }

  .borderless-container::before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  .borderless-container.work::after,
  .borderless-container.personal::after {
    font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f1ad";
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1rem;
    right: 1rem;
    height: 2.5rem;
    width: 2.5rem;
    background-color: #fad15f;
    border-radius: 100%;
    color: #f48a35;
  }
  
  .borderless-container.personal::after {
    content: "\f007";
    background-color: #aed8fb;
    color: #359ff4;
  }

  .borderless-container__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    height: auto;

    padding: 0.5rem;

    filter: brightness(50%) grayscale(100%);
    transition: all 400ms ease;
  }

  .borderless-container__title {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .borderless-container__title span {
    display: inline-block;
    line-height: initial;
    padding: 0.5rem;
    width: fit-content;
    text-align: center;
    transition: all 200ms ease;
  }

  .borderless-container:hover .borderless-container__title span {
    background-color: rgba(59,89, 152,0.9);
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .portfolio-container__box {
    margin: 0 0 1rem;
  }

  .borderless-container {
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 50%;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .portfolio-container__box .portfolio-container__box__content .title {
    font-size: 1rem;
  }

  .borderless-container {
    width: 33.33%;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  .portfolio-container__box {
    margin: 0;
  }
}