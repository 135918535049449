/* // Small devices (landscape phones, 300px and up) */
@media (min-width: 300px) {
  #navigation-bar {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 6rem;
    background-color: #ffffff;
    border-right: 1px solid #e7e7e7;
    text-align: center;
  
    font-size: 0.75rem;
  }
  
  #navigation-bar a {
    color: #cacaca;
    text-decoration: none;
  }
  
  #navigation-bar .navigation-bar__section {
    padding: 1rem 1rem 0;
  }
  
  #navigation-bar .navigation-bar__section.active .navigation-bar__section__icon {
    box-shadow: none;
    transform: translateY(3px);
    background-color: #3b5998;
    border-color: #3b5998;
    color: #f7f7f7;
  }
  
  #navigation-bar .navigation-bar__section.active:hover .navigation-bar__section__icon {
    box-shadow: none;
  }
  
  #navigation-bar .navigation-bar__section .navigation-bar__section__icon.main-icon {
    background-color:	transparent;
    border: none;
    box-shadow: none;
    color: #3b5998;
    pointer-events: none;
    font-size: 2rem;
    font-weight: bold;
  }
  
  #navigation-bar .navigation-bar__section .navigation-bar__section__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e7e7e7;
    border-radius: 0.25rem;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    margin: 0 auto 0.5rem;
  
    box-shadow: 0 3px 0 #e7e7e7;
  
    transition: all 200ms ease;
  }
  
  #navigation-bar .navigation-bar__section .navigation-bar__section__icon:hover {
    background-color: #3b5998;
    border: 1px solid #3b5998;
    box-shadow: 0 3px 0 rgba(59,89,152,0.6);
    color: #f7f7f7;
  }
  
  #navigation-bar .navigation-bar__section .navigation-bar__section__icon:hover + .navigation-bar__section__title {
    color: #3b5998;
  }
  
  #navigation-bar .navigation-bar__section .navigation-bar__section__icon:active {
    transform: translateY(3px);
    box-shadow: 0 0 0 rgba(59,89,152,0.6);
  }
  
  #navigation-bar .navigation-bar__section .navigation-bar__section__title {
    color: #545454;
  
    transition: all 200ms ease;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  #navigation-bar {
    display: block;
  }

  #navigation-bar .navigation-bar__section.linkedin {
    position: absolute;
    bottom: 1rem;
    left: 0;
    right: 0;
  }

  #navigation-bar .navigation-bar__section.linkedin .navigation-bar__section__icon--linkedin {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0077b5;
    border: 1px solid #0077b5;
    border-radius: 0.25rem;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    margin: 0 auto 0.5rem;
  
    box-shadow: 0 3px 0 rgba(0,119,181,0.6);
    transition: all 200ms ease;
  }

  #navigation-bar .navigation-bar__section.linkedin .navigation-bar__section__icon--linkedin:hover {
    transform: translateY(3px);
    box-shadow: 0 0 0 rgba(59,89,152,0.6);
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 

}