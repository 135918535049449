/* // Small devices (landscape phones, 300px and up) */
@media (min-width: 300px) { 
  section {
    background-color:	#f7f7f7;
    /* min-height: 100vh; */
    padding-bottom: 2rem;
  }
  
  .section__title {
    padding: 1rem 1rem 1rem 0;
    display: inline-block;
    color: #ffffff;
    background-color: #3b5998;
    font-size: 2rem;
    font-weight: 700;
    margin: 1rem 0;
    padding: 0.5rem 1rem;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .section__title {
    margin: 1rem 6rem 2rem 1rem;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .section__title {
    margin: 2rem 6rem 2rem 2rem;
  }

  section {
    padding-bottom: 2rem;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
}