/* // Small devices (landscape phones, 300px and up) */
@media (min-width: 300px) { 
  .subaccordion__panel__main {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #dfe3ee;
    border-bottom: 1px solid #ffffff;
    color: #3b5998;
    cursor: pointer;
    padding: 1rem 1rem 1rem 2.5rem;
  
    transition: all 400ms ease;
  }
  
  .subaccordion__panel__main:hover {
    background-color: #3b5998;
    color: #ffffff;
    transform: translateY(-0.25rem);
  }
  
  .subaccordion__panel__main:hover::before {
    border-color: #ffffff;
  }
  
  
  .subaccordion__panel__main::before {
    content: '';
    position: absolute;
    top: 1rem;
    left: 1rem;
    bottom: 1rem;
    border-left: 0.5rem solid #3b5998;
  
    transition: all 400ms ease;
  }
  
  .subaccordion__panel__main .subaccordion__panel__title {
    flex: 3 1 100%;
    margin: 0;
  }
  
  .subaccordion__panel__main .subaccordion__panel__company {
    font-size: 1rem;
    line-height: 1.125rem;
    flex: 1 1 100%;
    margin-bottom: 1rem;
  }
  
  .subaccordion__panel__main .subaccordion__panel__location {
    font-size: 1rem;
    line-height: 1.125rem;
    flex: 1 1 100%;
    text-align: left;
  }
  
  .subaccordion__panel__main:active {
    background-color: #3b5998;
    color: #ffffff;
    transform: translateY(0);
  }
  
  .subaccordion__panel__main.active {
    background-color: #3b5998;
    color: #ffffff;
    transform: translateY(0);
  }
  
  .subaccordion__panel__main.active::before {
    border-color: #ffffff;
  }

  .subaccordion__panel__main:not(:only-child)::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;

    font-family: "Font Awesome 5 Free"; font-size: 1.25rem; font-weight: 900; content: "\f0fe";
  }

  .subaccordion__panel__main.active:not(:only-child)::after {
    content: "\f146";
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 6rem;
  }
  
  .button-container .circle-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    height: 2rem;
    width: 2rem;
    padding: 0.5rem;
    background-color: #ffffff;
    color: #3b5998;
    cursor: pointer;
    transition: all 200ms ease;
  }
  
  .button-container .circle-button:hover {
    background-color: #dfe3ee;
    color: #3b5998;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .subaccordion__panel__main {
    padding: 1rem 4rem 1rem 5.5rem;
  }

  .subaccordion__panel__main::before {
    left: 4rem;
  }

  .subaccordion__panel__main .subaccordion__panel__title {
    margin: 0 0 0.5rem 0;
  }

  .subaccordion__panel__main .subaccordion__panel__company {
    flex: 1 1 50%;
    margin-bottom: 0;
  }
  
  .subaccordion__panel__main .subaccordion__panel__location {
    flex: 1 1 50%;
    text-align: right;
  }

  .button-container {
    right: 0;
  }

}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .subaccordion__panel__main:not(:only-child)::after {
    left: 0;
    right: initial;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  .subaccordion__panel__main .subaccordion__panel__company {
    flex: 1 1 80%;
    margin-bottom: 0;
  }
  
  .subaccordion__panel__main .subaccordion__panel__location {
    flex: 1 1 20%;
    text-align: right;
  }

  .subaccordion__panel__main {
    padding: 1rem 6rem 1rem 5.5rem;
  }
}