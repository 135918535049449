/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 275px) { 
  .Category {
    background-color: #fdfdfd;
    /* box-shadow: 0 1px 1px 0 rgba(60,64,67,.08), 0 1px 3px 1px rgba(60,64,67,.16); */
    /* width: 20rem; */
    /* padding: 1rem; */
    border: 1px solid #f0f0f0;
    margin-bottom: 1rem;
  }
  
  .Category .title {
    background-color: #f9f9f9;
    border-bottom: 1px solid #f0f0f0;
    transition: background-color 200ms ease;
  }
  
  .Category .title:only-child {
    border-bottom: none;
  }
  
  .Category .body {
    padding: 1rem;
  }
  
  .Category h5 {
    color: #007bff;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0;
    padding: 0.75rem;
  
    transition: color 200ms ease;
  }
  
  .Category ul {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }
  
  .Category ul li span {
    display: inline-block;
    font-size: 0.875rem;
    line-height: 0.875rem;
    padding: 0.25rem 0;
  
    transition: border-left-color 200ms, color 200ms ease;
  }
  
  .Category ul li span:hover {
    border-left-color: #551a8b;
  }
  
  .Category h5:hover,
  .Category h5:hover > a,
  .Category ul li span:hover {
    color: #551a8b;
  }
  
  .Category .title:hover {
    background-color: #f4f4f4;
  }
  
  .Category a:hover {
    text-decoration: none;
  }
  
  .columns1 {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
  
  .columns2 {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
  
  .columns3 {
    columns: 3;
    -webkit-columns: 3;
    -moz-columns: 3;
  }
  
  .columns3 span {
    font-size: 0.75rem !important;
    line-height: 0.75rem !important;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .columns1 {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
  
  .columns2 {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
  
  .columns3 {
    columns: 3;
    -webkit-columns: 3;
    -moz-columns: 3;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .Category ul li span {
    padding: 0.25rem 0.5rem;
    border-left: 0.25rem solid transparent;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  
}