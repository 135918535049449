/* // Small devices (landscape phones, 300px and up) */
@media (min-width: 300px) { 
  #footer {
    display: flex;
    align-items: center;
    justify-content: center;
  
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
  
    height: 4rem;
    z-index: -1;
  
    color: #ffffff;
    background-color: #8b9dc3;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  #footer {
    left: 6rem;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  
}