@import url(https://use.fontawesome.com/releases/v5.7.1/css/all.css);
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Mark Pro';
  src: url(/static/media/MarkPro.12d6724a.otf);
  font-weight: 400;
}

@font-face {
  font-family: 'Mark Pro';
  src: url(/static/media/MarkPro-Bold.476d44b0.otf);
  font-weight: 700;
}

@font-face {
  font-family: 'Mark Pro';
  src: url(/static/media/MarkPro-Black.cf35f412.otf);
  font-weight: 900;
}

/* // Small devices (landscape phones, 300px and up) */
@media (min-width: 300px) { 

}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 

}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  
}

.custom-display-1 {
  font-size: 3rem;
  font-weight: 500;
}

.custom-display-2 {
  font-size: 2rem;
  font-weight: 700;
}

.custom-display-3 {
  font-size: 1.5rem;
  font-weight: 700;
}

.custom-display-4 {
  font-size: 1rem;
  font-weight: 700;
}
/* // Small devices (landscape phones, 300px and up) */
@media (min-width: 300px) { 
  .App {
    position: relative;
  }
  
  .App .mobile-navigation-bar__section__icon--linkedin {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0077b5;
    border: 1px solid #0077b5;
    border-radius: 0.25rem;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    margin: 0 auto 0.5rem;
  
    box-shadow: 0 3px 0 rgba(0,119,181,0.6);
    transition: all 200ms ease;
    z-index: 2;
  }
  
  .App .mobile-navigation-bar__section__icon--linkedin:hover {
    -webkit-transform: translateY(3px);
            transform: translateY(3px);
    box-shadow: 0 0 0 rgba(59,89,152,0.6);
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .App .mobile-navigation-bar__section__icon--linkedin { 
    display: none;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  
}
/* // Small devices (landscape phones, 300px and up) */
@media (min-width: 300px) {
  #navigation-bar {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 6rem;
    background-color: #ffffff;
    border-right: 1px solid #e7e7e7;
    text-align: center;
  
    font-size: 0.75rem;
  }
  
  #navigation-bar a {
    color: #cacaca;
    text-decoration: none;
  }
  
  #navigation-bar .navigation-bar__section {
    padding: 1rem 1rem 0;
  }
  
  #navigation-bar .navigation-bar__section.active .navigation-bar__section__icon {
    box-shadow: none;
    -webkit-transform: translateY(3px);
            transform: translateY(3px);
    background-color: #3b5998;
    border-color: #3b5998;
    color: #f7f7f7;
  }
  
  #navigation-bar .navigation-bar__section.active:hover .navigation-bar__section__icon {
    box-shadow: none;
  }
  
  #navigation-bar .navigation-bar__section .navigation-bar__section__icon.main-icon {
    background-color:	transparent;
    border: none;
    box-shadow: none;
    color: #3b5998;
    pointer-events: none;
    font-size: 2rem;
    font-weight: bold;
  }
  
  #navigation-bar .navigation-bar__section .navigation-bar__section__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e7e7e7;
    border-radius: 0.25rem;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    margin: 0 auto 0.5rem;
  
    box-shadow: 0 3px 0 #e7e7e7;
  
    transition: all 200ms ease;
  }
  
  #navigation-bar .navigation-bar__section .navigation-bar__section__icon:hover {
    background-color: #3b5998;
    border: 1px solid #3b5998;
    box-shadow: 0 3px 0 rgba(59,89,152,0.6);
    color: #f7f7f7;
  }
  
  #navigation-bar .navigation-bar__section .navigation-bar__section__icon:hover + .navigation-bar__section__title {
    color: #3b5998;
  }
  
  #navigation-bar .navigation-bar__section .navigation-bar__section__icon:active {
    -webkit-transform: translateY(3px);
            transform: translateY(3px);
    box-shadow: 0 0 0 rgba(59,89,152,0.6);
  }
  
  #navigation-bar .navigation-bar__section .navigation-bar__section__title {
    color: #545454;
  
    transition: all 200ms ease;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  #navigation-bar {
    display: block;
  }

  #navigation-bar .navigation-bar__section.linkedin {
    position: absolute;
    bottom: 1rem;
    left: 0;
    right: 0;
  }

  #navigation-bar .navigation-bar__section.linkedin .navigation-bar__section__icon--linkedin {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0077b5;
    border: 1px solid #0077b5;
    border-radius: 0.25rem;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    margin: 0 auto 0.5rem;
  
    box-shadow: 0 3px 0 rgba(0,119,181,0.6);
    transition: all 200ms ease;
  }

  #navigation-bar .navigation-bar__section.linkedin .navigation-bar__section__icon--linkedin:hover {
    -webkit-transform: translateY(3px);
            transform: translateY(3px);
    box-shadow: 0 0 0 rgba(59,89,152,0.6);
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 

}
/* // Small devices (landscape phones, 300px and up) */
@media (min-width: 300px) { 
  #main-container {
    margin-left: 0;
    min-height: 100vh;
    background-color: #f7f7f7;
  
    margin-bottom: 4rem;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  #main-container {
    margin-left: 6rem;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  
}
/* // Small devices (landscape phones, 300px and up) */
@media (min-width: 300px) { 
  #footer {
    display: flex;
    align-items: center;
    justify-content: center;
  
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
  
    height: 4rem;
    z-index: -1;
  
    color: #ffffff;
    background-color: #8b9dc3;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  #footer {
    left: 6rem;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  
}
/* // Small devices (landscape phones, 300px and up) */
@media (min-width: 300px) { 
  #overview {
    background-color: #ffffff;
    padding-bottom: 0;
  }
  
  #overview .section-container {
    display: flex;
    align-items: center;
    position: relative;
    height: 100vh;
  }
  
  #overview h1 {
    color: #3b5998;
    display: inline-block;
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 3rem;
    /* margin: 6rem 1rem 1rem; */
    margin-left: 1rem;
  }
  
  #overview h1 .highlight {
    background-color: #3b5998;
    color: #ffffff;
    padding: 0.25rem 1rem;
  }
  
  #overview .box {
    position: absolute;
    /* right: 0; */
    background-color: #3b5998;
    z-index: 1;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 

  #overview h1 {
    font-size: 3rem;
    line-height: 5rem;
    /* margin: 3rem 0 0 3rem; */
    margin-left: 3rem;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  #overview h1 {
    font-size: 3rem;
    line-height: 5rem;
    /* margin: 3rem 0 0 3rem; */
    width: 49rem;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  
}
/* // Small devices (landscape phones, 300px and up) */
@media (min-width: 300px) { 
  section {
    background-color:	#f7f7f7;
    /* min-height: 100vh; */
    padding-bottom: 2rem;
  }
  
  .section__title {
    padding: 1rem 1rem 1rem 0;
    display: inline-block;
    color: #ffffff;
    background-color: #3b5998;
    font-size: 2rem;
    font-weight: 700;
    margin: 1rem 0;
    padding: 0.5rem 1rem;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .section__title {
    margin: 1rem 6rem 2rem 1rem;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .section__title {
    margin: 2rem 6rem 2rem 2rem;
  }

  section {
    padding-bottom: 2rem;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
}
/* // Small devices (landscape phones, 300px and up) */
@media (min-width: 300px) { 
  #portfolio .section-content {
    line-height: 0;
  }

  .key-container {
    padding: 0 4rem 2rem 4rem;
  }
  
  .key-container ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .key-container li {
    position: relative;
    display: inline-block;
    font-weight: 500;
    padding: 1rem 2rem 1rem 4rem;
    margin-right: 1rem;
    border-radius: 0.25rem;
  }
  
  .key-container li.work {
    background-color: #fad15f;
    border: 1px solid #f48a35;
    color: #f48a35;
  }
  
  .key-container li.work::before {
    font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\F1AD";
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 4rem;
    color: #f48a35;
  }
  
  .key-container li.personal {
    background-color: #aed8fb;
    border: 1px solid #359ff4;
    color: #359ff4;
  }
  
  .key-container li.personal::before {
    font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\F007";
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 4rem;
    color: #359ff4;
  }
  
  /* .portfolio-container__box {
    position: relative;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #e7e7e7;
    cursor: pointer;
    margin: 0 0 1rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
    transition: all 200ms ease;
  }
  
  .portfolio-container__box.work::before,
  .portfolio-container__box.personal::before {
    font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f1ad";
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1rem;
    right: 1rem;
    height: 2.5rem;
    width: 2.5rem;
    background-color: #fad15f;
    border-radius: 100%;
    color: #f48a35;
  }
  
  .portfolio-container__box.personal::before {
    content: "\f007";
    background-color: #aed8fb;
    color: #359ff4;
  }
  
  .portfolio-container__box:hover {
    transform: translateY(-0.25rem);
    box-shadow: 0 4px 0 rgba(223,227,238,0.6);
  }
  
  .portfolio-container__box .portfolio-container__box__image {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
    border-radius: 0.25rem;
    border: 1px solid #e7e7e7;
  }
  
  .portfolio-container__box .portfolio-container__box__content {
    background-color: #ffffff;
    margin-top: 0.5rem;
    text-align: center;
  }
  
  .portfolio-container__box .portfolio-container__box__content .title {
    background-color: #dfe3ee;
    border: 1px solid #3b5998;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #3b5998;
    padding: 0.75rem 1.5rem;
  
    transition: all 200ms ease;
  }
  
  .portfolio-container__box:hover .portfolio-container__box__content .title {
    background-color: #3b5998;
    color: #ffffff;
  } */

  .borderless-container {
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 100%
  }

  .borderless-container:hover .borderless-container__image {
    -webkit-filter: brightness(100%);
            filter: brightness(100%);
  }

  .borderless-container::before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  .borderless-container.work::after,
  .borderless-container.personal::after {
    font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\F1AD";
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1rem;
    right: 1rem;
    height: 2.5rem;
    width: 2.5rem;
    background-color: #fad15f;
    border-radius: 100%;
    color: #f48a35;
  }
  
  .borderless-container.personal::after {
    content: "\F007";
    background-color: #aed8fb;
    color: #359ff4;
  }

  .borderless-container__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    height: auto;

    padding: 0.5rem;

    -webkit-filter: brightness(50%) grayscale(100%);

            filter: brightness(50%) grayscale(100%);
    transition: all 400ms ease;
  }

  .borderless-container__title {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .borderless-container__title span {
    display: inline-block;
    line-height: normal;
    line-height: initial;
    padding: 0.5rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    transition: all 200ms ease;
  }

  .borderless-container:hover .borderless-container__title span {
    background-color: rgba(59,89, 152,0.9);
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .portfolio-container__box {
    margin: 0 0 1rem;
  }

  .borderless-container {
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 50%;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .portfolio-container__box .portfolio-container__box__content .title {
    font-size: 1rem;
  }

  .borderless-container {
    width: 33.33%;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  .portfolio-container__box {
    margin: 0;
  }
}
#experiences .accordion {
  background-color: #e7e7e7;
  list-style: none;
  margin: 0;
  padding: 0;
}
/* // Small devices (landscape phones, 300px and up) */
@media (min-width: 300px) { 
  .accordion__panel {
    border-bottom: 1px solid #e7e7e7;
  }
  
  .accordion__panel__main {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #f7f7f7;
    color: #3b5998;
    cursor: pointer;
    padding: 1rem;
  
    transition: all 400ms ease;
  }
  
  .accordion__panel__main:hover {
    background-color: #3b5998;
    color: #ffffff;
    -webkit-transform: translateY(-0.25rem);
            transform: translateY(-0.25rem);
  }
  
  .accordion__panel__main:active {
    background-color: #3b5998;
    color: #ffffff;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  
  .accordion__panel__main.active {
    background-color: #3b5998;
    color: #ffffff;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  
  .accordion__panel__main:only-child {
    pointer-events: none;
  }

  .accordion__panel__main:not(:only-child)::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;

    font-family: "Font Awesome 5 Free"; font-size: 1.25rem; font-weight: 900; content: "\F0FE";
  }

  .accordion__panel__main.active:not(:only-child)::after {
    content: "\F146";
  }
  
  .accordion__panel__main .accordion__panel__title {
    margin-right: 1rem;
    flex: 3 1 100%;
  }
  
  .accordion__panel__main .accordion__panel__company,
  .accordion__panel__main .accordion__panel__location,
  .accordion__panel__main .accordion__panel__duration {
    color: #8b9dc3;
  }
  
  .accordion__panel__main:hover .accordion__panel__company,
  .accordion__panel__main:hover .accordion__panel__location,
  .accordion__panel__main:hover .accordion__panel__duration,
  .accordion__panel__main.active .accordion__panel__company,
  .accordion__panel__main.active .accordion__panel__location,
  .accordion__panel__main.active .accordion__panel__duration {
    color: inherit;
  }
  
  .accordion__panel__main .accordion__panel__company {
    flex: 1 1 100%;
    margin-bottom: 1rem;
  }
  
  .accordion__panel__main .accordion__panel__location {
    flex: 1 1 50%;
    font-size: 0.875rem;
  }
  
  .accordion__panel__main .accordion__panel__location::before,
  .subaccordion__panel__main .subaccordion__panel__location::before {
    font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\F3C5";
    margin-right: 0.5rem;
  }
  
  .accordion__panel__main .accordion__panel__duration {
    flex: 1 1 50%;
    text-align: right;
    font-size: 0.875rem;
  }
  
  .accordion__panel .accordion__panel__duration::before {
    font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\F017";
    margin-right: 0.5rem;
  }
  
  .accordion__panel__body {
    background-color: #e7e7e7;
    transition: all 400ms ease;
  }
  
  .subaccordion {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .subaccordion__panel:last-of-type .subaccordion__panel__main {
    border-bottom: none;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .accordion__panel__main {
    padding: 1.5rem 2rem 1.5rem 2rem;
  }

  .accordion__panel__main .accordion__panel__title {
    margin-right: 0;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .accordion__panel__main .accordion__panel__title {
    margin: 0 0 .5rem;
  }

  .accordion__panel__main {
    padding: 1.5rem 4rem 1.5rem 4rem;
  }

  .accordion__panel__main:not(:only-child)::after {
    left: 0;
    right: auto;
    right: initial;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  .accordion__panel__main {
    padding: 1.5rem 6rem 1.5rem 4rem;
  }

  .accordion__panel__main .accordion__panel__company {
    font-size: 1rem;
    line-height: 1.125rem;
    flex: 1 1 60%;
    margin-bottom: 0;
  }
  
  .accordion__panel__main .accordion__panel__location {
    font-size: 1rem;
    line-height: 1.125rem;
    flex: 1 1 20%;
    font-size: initial;
  }
  
  .accordion__panel__main .accordion__panel__duration {
    font-size: 1rem;
    line-height: 1.125rem;
    flex: 1 1 20%;
    font-size: initial;
  }
}
/* // Small devices (landscape phones, 300px and up) */
@media (min-width: 300px) { 
  .accordion__panel__body.description {
    background-color: #ffffff;
    padding: 1rem;
  }
  
  .accordion__panel__body.description .description-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .accordion__panel__body.description .description-list li {
    position: relative;
    background-color: #dfe3ee;
    border: 1px solid #8b9dc3;
    border-bottom: none;
    color: #3b5998;
    padding: 1rem 2rem;
  }
  
  .accordion__panel__body.description .description-list li::before {
    font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\F0DA";
    position: absolute;
    top: 1.0625rem;
    left: 1rem;
  }
  
  .accordion__panel__body.description .description-list li:last-of-type {
    border-bottom: 1px solid #8b9dc3;
  }
  
  .accordion__panel__body.description .skill-list {
    display: inline-block;
    list-style: none;
    margin: 0 0 1rem 0;
    padding: 0;
  }
  
  .accordion__panel__body.description .skill-list li {
    position: relative;
    display: inline-block;
    background-color: #3b5998;
    border: 1px solid #3b5998;
    color: #ffffff;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
  }
  
  .accordion__panel__body.description .category-title {
    display: inline-block;
    background-color: #ffffff;
    border: 4px solid #3b5998;
    color: #3b5998;
    font-weight: bold;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .accordion__panel__body.description .skill-list li::before {
    content: '';
    position: absolute;
    top: 50%;
    right: calc(100% + 1px);
    width: 1rem;
    height: 2px;
    background-color: #3b5998;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  .accordion__panel__body.description {
    background-color: #ffffff;
    padding: 1.5rem 6rem 1.5rem 4rem;
  }

  .accordion__panel__body.description .category-title {
    margin-bottom: 0;
  }

  .accordion__panel__body.description .skill-list li {
    margin-bottom: 0;
  }
}
/* // Small devices (landscape phones, 300px and up) */
@media (min-width: 300px) { 
  .subaccordion__panel__main {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #dfe3ee;
    border-bottom: 1px solid #ffffff;
    color: #3b5998;
    cursor: pointer;
    padding: 1rem 1rem 1rem 2.5rem;
  
    transition: all 400ms ease;
  }
  
  .subaccordion__panel__main:hover {
    background-color: #3b5998;
    color: #ffffff;
    -webkit-transform: translateY(-0.25rem);
            transform: translateY(-0.25rem);
  }
  
  .subaccordion__panel__main:hover::before {
    border-color: #ffffff;
  }
  
  
  .subaccordion__panel__main::before {
    content: '';
    position: absolute;
    top: 1rem;
    left: 1rem;
    bottom: 1rem;
    border-left: 0.5rem solid #3b5998;
  
    transition: all 400ms ease;
  }
  
  .subaccordion__panel__main .subaccordion__panel__title {
    flex: 3 1 100%;
    margin: 0;
  }
  
  .subaccordion__panel__main .subaccordion__panel__company {
    font-size: 1rem;
    line-height: 1.125rem;
    flex: 1 1 100%;
    margin-bottom: 1rem;
  }
  
  .subaccordion__panel__main .subaccordion__panel__location {
    font-size: 1rem;
    line-height: 1.125rem;
    flex: 1 1 100%;
    text-align: left;
  }
  
  .subaccordion__panel__main:active {
    background-color: #3b5998;
    color: #ffffff;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  
  .subaccordion__panel__main.active {
    background-color: #3b5998;
    color: #ffffff;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  
  .subaccordion__panel__main.active::before {
    border-color: #ffffff;
  }

  .subaccordion__panel__main:not(:only-child)::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;

    font-family: "Font Awesome 5 Free"; font-size: 1.25rem; font-weight: 900; content: "\F0FE";
  }

  .subaccordion__panel__main.active:not(:only-child)::after {
    content: "\F146";
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 6rem;
  }
  
  .button-container .circle-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    height: 2rem;
    width: 2rem;
    padding: 0.5rem;
    background-color: #ffffff;
    color: #3b5998;
    cursor: pointer;
    transition: all 200ms ease;
  }
  
  .button-container .circle-button:hover {
    background-color: #dfe3ee;
    color: #3b5998;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .subaccordion__panel__main {
    padding: 1rem 4rem 1rem 5.5rem;
  }

  .subaccordion__panel__main::before {
    left: 4rem;
  }

  .subaccordion__panel__main .subaccordion__panel__title {
    margin: 0 0 0.5rem 0;
  }

  .subaccordion__panel__main .subaccordion__panel__company {
    flex: 1 1 50%;
    margin-bottom: 0;
  }
  
  .subaccordion__panel__main .subaccordion__panel__location {
    flex: 1 1 50%;
    text-align: right;
  }

  .button-container {
    right: 0;
  }

}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .subaccordion__panel__main:not(:only-child)::after {
    left: 0;
    right: auto;
    right: initial;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  .subaccordion__panel__main .subaccordion__panel__company {
    flex: 1 1 80%;
    margin-bottom: 0;
  }
  
  .subaccordion__panel__main .subaccordion__panel__location {
    flex: 1 1 20%;
    text-align: right;
  }

  .subaccordion__panel__main {
    padding: 1rem 6rem 1rem 5.5rem;
  }
}
/* // Small devices (landscape phones, 300px and up) */
@media (min-width: 300px) { 
  #skills {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAAAAACMIRMSAAAAmElEQVR4AX2RwQ4EIQhD/f9ftYW7p6U0buYiEmLG5fGUXRlELda2eyEJ6mDFea36Kf8RZ1egUjFTOFTtn4RSFN6Uu7Pq0bu7iFx8U/ZEh3yyyEk1fFIy+CXiXB+Ve3TdGVxuN6XTeRo0Y6d38aMLH8ZvtBXjDK/BtayAreM0dBdXZqcsdq+cJv/9f/3l286UK68zZVKX6V0/HECKK51+49AAAAAASUVORK5CYII=);
  }
  
  .skill-category__container {
    margin: 0 1rem 1rem;
  }
  
  .skill-category__container__title {
    display: inline-block;
    background-color: #3b5998;
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 0.5rem 1rem;
  }
  
  .skill-category__container__content {
    padding: 1rem 0;
  }
  
  .skill-category__container__content .skill-card {
    position: relative;
    display: inline-block;
    background-color: #ffffff;
    border: 1px solid #e7e7e7;
    border-radius: 0.25rem;
    box-shadow: 0 3px 0 rgba(0,0,0,0.1);
    margin-bottom: 1rem;
    padding: 0.5rem;
    margin-right: 0.5rem;
    width: 100%;
  }

  .skill-category__container__content .skill-card::before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
  
  .skill-category__container__content .skill-card:last-of-type {
    margin-right: 0;
  }
  
  .skill-card .skill-card__image__container {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    left: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f7f7f7;
    border: 1px solid #e7e7e7;
  }

  .skill-card .skill-card__image__container::before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
  
  .skill-card .skill-card__image__container__icon {
    font-size: 5rem;
  }
  
  .skill-card .skill-card__label {
    color: #7b7b7b;
    font-weight: bold;
    margin: 0.5rem 0 0;
    text-align: center;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .skill-category__container {
    margin: 0 2rem 2rem 2rem;
  }

  .skill-category__container__content {
    padding: 1rem;
  }

  .skill-category__container__content .skill-card {
    margin-right: 1rem;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .skill-category__container {
    margin: 0 6rem 1rem 4rem;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
}
@media only screen and (min-width: 300px) {
  .mobile-layover {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    background-color: gray;
    padding: 0 2rem;

    z-index: 1;
  }

  .mobile-layover h3 {
    text-align: center;
  }
}

@media only screen and (min-width: 768px) {
  .mobile-layover {
    display: none;
  }
}

#BCG div {
  font-family: 'Mark Pro', sans-serif;
}

#BCG .object-top,
#BCG .object-bottom,
#BCG .empty {
  background-color: #ebebeb;
  display: block;
  position: relative;
  padding-top: 2rem;
}
#BCG .object-top.big,
#BCG .object-bottom.big {
  padding-top: 4rem;
}
#BCG .object-top::before,
#BCG .object-bottom::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 4rem;
  right: 4rem;
  bottom: 0;
  border-left: 1px solid white;
  border-right: 1px solid white;
}
#BCG .object-top::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 1px solid white;
}
#BCG .object-bottom::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 1px solid white;
}
#BCG .content {
  display: block;
  position: relative;
  padding: 0 4rem;
  background-color: #ebebeb;
}
#BCG .sub-content {
  /* padding: 20px; */
  border-bottom: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
}
#BCG .sub-content:last-of-type {
  border-bottom: none;
}
#BCG .sub-content [class^="col"] {
  border-right: 1px solid white;
  padding: 0;
}
#BCG .sub-content [class^="col"]:last-of-type {
  border-right: none;
}
#BCG .sub-content [class^="col"]:nth-of-type(3n) {
  border-right: none;
}
#BCG .sub-content [class^="col"]:nth-of-type(n + 4) {
  border-top: 1px solid white;
}
#BCG .dropdown-panel {
  display: block;
  position: relative;
  padding: 1rem;
  transition: background-color 300ms ease;
  cursor: pointer;
}
#BCG .dropdown-panel::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 4px;
  background-color: #008caa;
  opacity: 0;
  transition: opacity 300ms ease;
}
#BCG .dropdown-panel.active {
  /* background-color: #dfdfdf; */
  background-color: #282828;
}
#BCG .dropdown-panel.active > .title {
  /* color: #008caa; */
  color: white;
}
#BCG .dropdown-panel.active > .category {
  color: #dfdfdf;
}
#BCG .dropdown-panel.active > .title:before {
  background-color: white;
}
#BCG .dropdown-panel.active:hover > .title:before {
  background-color: #282828;
}
#BCG .dropdown-panel.active:hover > .category {
  color: gray;
}
#BCG .dropdown-panel:hover {
  background-color: #dfdfdf;
}
#BCG .dropdown-panel:hover::after {
  opacity: 1;
}
#BCG .dropdown-panel:hover > .title,
#BCG .job-card:hover > .title {
  color: #008caa;
}
#BCG .job-card.active > .title {
  color: white;
}
#BCG .dropdown-panel > .category {
  text-transform: uppercase;
  color: gray;
  font-size: 0.75rem;
}
#BCG .section-title,
#BCG .dropdown-panel > .title,
#BCG .dropdown-panel.active:hover > .title {
  /* font-weight: bold; */
  position: relative;
  color: #464646;
  font-size: 1.25rem;
  transition: color 300ms ease;
}
#BCG .section-title {
  font-size: 17px;
  padding: 2rem 5rem 1rem;
  text-transform: uppercase;
}
#BCG .title-message {
  padding: 1rem;
}
#BCG .dropdown-panel > .title::before {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  background-color: #282828;
  -webkit-mask-image: url(/static/media/chevron-down-solid.6b467e1b.svg);
  mask-image: url(/static/media/chevron-down-solid.6b467e1b.svg);
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: 1.5rem 1.5rem;
          mask-size: 1.5rem 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
}
#BCG .dropdown-panel > .title.active::before {
  -webkit-mask-image: url(/static/media/chevron-up-solid.aa2b728d.svg);
  mask-image: url(/static/media/chevron-up-solid.aa2b728d.svg);
}
#BCG .dropdown-panel > .dropdown-list {
  display: block;
  position: absolute;
  max-height: 20rem;
  overflow-y: scroll;
  left: 0;
  right: 0;
  top: 100%;
  background: white;
  z-index: 1000;
  /* border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf; */
  outline: 1px solid #dfdfdf;
}
#BCG .dropdown-panel > .dropdown-list > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#BCG .dropdown-panel > .dropdown-list > ul > li {
  padding: 0.5rem 1rem;
  color: #464646;
}
#BCG .dropdown-panel > .dropdown-list > ul > li:hover {
  color: white;
  background-color: #464646;
}
#BCG .dropdown-panel > .dropdown-list > ul > li:active {
  background-color: #008caa;
}
#BCG .media-card,
#BCG .job-card {
  display: block;
  position: relative;
  padding: 1rem;
  transition: background-color 300ms ease;
}
#BCG #job-list-panel {
  height: 600px;
  overflow-y: overlay;
}
#BCG #job-list-panel [class^="col"] {
  border: none;
  border-bottom: 1px solid white;
}
#BCG #job-description-panel {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  height: 600px;
}
#BCG #job-description-panel-top,
#BCG #job-description-panel-bottom {
  padding: 1rem;
}
#BCG #job-description-panel-top {
  border-bottom: 1px solid #ebebeb;
  flex: 0 0 auto;
}
#BCG #job-description-panel-bottom {
  padding: 2rem 1rem 3rem;
  flex: 0 1 auto;
  overflow-y: scroll;
}
#BCG #job-list-panel::-webkit-scrollbar,
#BCG #job-description-panel-bottom::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}
#BCG #job-list-panel::-webkit-scrollbar-thumb,
#BCG #job-description-panel-bottom::-webkit-scrollbar-thumb {
  background-color: rgba(70, 70, 70, 0.25);
}
#BCG .media-card > .description {
  margin-bottom: 0;
  font-size: 0.875rem;
}
#BCG .media-card > .time-stamp,
#BCG #job-description-panel .team,
#BCG #job-description-panel .location {
  margin-top: 1rem;
  margin-bottom: 0;
  color: gray;
  font-size: 0.875rem;
}
#BCG .job-card:hover {
  background-color: white;
  cursor: pointer;
}
#BCG .job-card.active {
  /* background-color: #dfdfdf; */
  background-color: #464646;
  cursor: auto;
  cursor: initial;
}
#BCG .job-card > .tag {
  padding: 4px 8px;
  margin-right: 8px;
  font-size: 0.75rem;
  color: #464646;
  background-color: white;
  transition: background-color 300ms, color 300ms ease;
  cursor: pointer;
}
#BCG .job-card:hover > .tag {
  background-color: #dfdfdf;
}
#BCG .job-card.active > .tag {
  background-color: white;
}
#BCG .job-card > .tag:hover {
  color: #008caa;
}
#BCG .job-card > .tag:active {
  transition: none;
  /* background-color: #282828; */
  background-color: #dfdfdf;
  /* color: white; */
  color: #464646;
}
#BCG .job-card > .title {
  font-size: 1.125rem;
  /* font-weight: bold; */
  margin-bottom: 0.5rem;
  /* min-height: 3.75rem; */
  transition: color 300ms ease;
}
#BCG #job-description-panel .title {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}
#BCG #job-description-panel .description {
  margin: 0;
  font-size: 0.875rem;
  white-space: pre-line;
}
#BCG .columnCollapse {
  padding: 0 !important;
}
#BCG .expandable {
  background-color: inherit;
  transition: background-color 300ms ease;
}
#BCG .expandable:hover {
  background-color: #dfdfdf;
  cursor: pointer;
}
#BCG .button {
  margin-top: 1rem;
  padding: 0.25rem 0.75rem;
  background-color: #282828;
  color: white;
  border: none;
  cursor: pointer;
  /* font-weight: bold; */
  transition: background-color 300ms, color 300ms ease;
  text-transform: uppercase;
}
#BCG .button:active {
  transition: none;
  background-color: #dfdfdf;
  color: #464646;
}
#BCG .button:focus {
  outline: none;
}
#BCG .logo {
  float: right;
  height: 75px;
  max-width: 125px;
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 275px) { 
  .craigslist {
    background: #f9f9f9;
    padding-top: 2rem;
  }
  
  .craigslist .nopadding {
    padding: 0;
  }
  
  .craigslist .left-bar h5 {
    background-color: #007bff;
    color: #fdfdfd;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    margin: 0 auto 1rem;
    padding: 0.75rem;
    transition: background-color 200ms ease;
  }
  
  .craigslist .left-bar h5:hover {
    background-color: #0072eb;
  }
  
  .craigslist .react-calendar {
    background-color: #fdfdfd;
    border: 1px solid #f0f0f0;
    font-family: inherit;
    margin: 2rem auto 2rem;
  }
  
  .craigslist .react-calendar * {
    font-size: 0.875rem;
  }
  
  .craigslist .react-calendar .react-calendar__month-view__weekdays__weekday abbr {
    font-size: 0.5rem;
    text-decoration: underline;
  }
  
  .craigslist .react-calendar button:focus {
    outline: none;
  }
  
  .craigslist .react-calendar__tile--active {
    background-color: #007bff;
  }
  
  .craigslist footer {
    padding: 1rem 0;
    background-color: #f4f4f4;
    font-size: 0.875rem;
    text-align: center;
  }
  
  .craigslist .clfooter {
    margin: 0;
    padding: 0;
  }
  
  .craigslist .clfooter li{
    display: inline-block;
    margin: 0 0.5rem;
  }
  
  sup {
    color: orange;
  }
  
  .craigslist .error-message {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
  
    padding: 0.5rem 1rem;
  
    color: #fdfdfd;
    background-color: #ff007b;
    font-size: 1rem;
    text-align: center;
    z-index: 2;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .craigslist .react-calendar {
    width: 500px;
  }

  .craigslist footer {
    margin-top: 2rem;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .craigslist .react-calendar {
    width: auto;
    width: initial;
    margin: 1rem auto 0;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 275px) { 
  .Category {
    background-color: #fdfdfd;
    /* box-shadow: 0 1px 1px 0 rgba(60,64,67,.08), 0 1px 3px 1px rgba(60,64,67,.16); */
    /* width: 20rem; */
    /* padding: 1rem; */
    border: 1px solid #f0f0f0;
    margin-bottom: 1rem;
  }
  
  .Category .title {
    background-color: #f9f9f9;
    border-bottom: 1px solid #f0f0f0;
    transition: background-color 200ms ease;
  }
  
  .Category .title:only-child {
    border-bottom: none;
  }
  
  .Category .body {
    padding: 1rem;
  }
  
  .Category h5 {
    color: #007bff;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0;
    padding: 0.75rem;
  
    transition: color 200ms ease;
  }
  
  .Category ul {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }
  
  .Category ul li span {
    display: inline-block;
    font-size: 0.875rem;
    line-height: 0.875rem;
    padding: 0.25rem 0;
  
    transition: border-left-color 200ms, color 200ms ease;
  }
  
  .Category ul li span:hover {
    border-left-color: #551a8b;
  }
  
  .Category h5:hover,
  .Category h5:hover > a,
  .Category ul li span:hover {
    color: #551a8b;
  }
  
  .Category .title:hover {
    background-color: #f4f4f4;
  }
  
  .Category a:hover {
    text-decoration: none;
  }
  
  .columns1 {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
  
  .columns2 {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
  
  .columns3 {
    columns: 3;
    -webkit-columns: 3;
    -moz-columns: 3;
  }
  
  .columns3 span {
    font-size: 0.75rem !important;
    line-height: 0.75rem !important;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .columns1 {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
  
  .columns2 {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
  
  .columns3 {
    columns: 3;
    -webkit-columns: 3;
    -moz-columns: 3;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .Category ul li span {
    padding: 0.25rem 0.5rem;
    border-left: 0.25rem solid transparent;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 275px) { 
  .top-header {
    margin-bottom: 1rem;
    /* text-align: center */
  }
  
  .top-header .logo {
    color: #551a8b;
    font-family: 'Times New Roman', Times, serif;
    font-size: 2.5rem;
    font-weight: 500;
    float: left;
  }
  
  .top-header #my-account {
    float: right;
  }
  
  .top-header #my-account h5 {
    color: #007bff;
    cursor: pointer;
    font-weight: bold;
    padding: 0.75rem;
    transition: color 200ms ease;
  }
  
  .top-header #my-account h5:hover {
    color: #551a8b;
  }
  
  .search-bar {
    float: left;
    position: relative;
    height: 3.75rem;
    width: 100%;
    background-color: #fdfdfd;
    border: 1px solid #f0f0f0;
    margin-bottom: 0;
    padding: 1rem;
  
    transition: all 200ms ease;
  }
  
  .search-bar .label {
    position: absolute;
    bottom: 0.875rem;
    left: 1rem;
    font-size: 1rem;
    color: #9098A9;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    transition: all .2s ease;
  }
  
  .search-bar .borderline {
    position: absolute;
    bottom: 0.5rem;
    left: 1rem;
    height: 2px;
    width: calc(100% - 2rem);
    background-color: #007bff;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    transition: all 200ms ease;
  }
  
  .search-bar input {
    position: absolute;
    left: 1rem;
    bottom: 0.5rem;
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid #f0f0f0;
  
    color: #223254;
  
    font-size: 1rem;
    line-height: 1.875rem;
  
    height: calc(2rem - 2px);
    width: calc(100% - 2rem);
    transition: all 200ms ease;
  }
  
  /* .search-bar:hover {
    background-color: #f4f4f4;
  } */
  
  .search-bar input:not(:placeholder-shown) ~ .label {
    color: #5A667F;
    -webkit-transform: translateY(-1rem) scale(0.75);
            transform: translateY(-1rem) scale(0.75);
  }
  
  .search-bar input:focus {
    outline: none;
  }
  
  .search-bar input:focus ~ .label {
    color: #007bff;
    -webkit-transform: translateY(-1rem) scale(0.75);
            transform: translateY(-1rem) scale(0.75);
  }
  
  .search-bar input:focus ~ .borderline {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  
  .search-bar input:not(:placeholder-shown) ~ .label {
    color: #007bff;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .search-bar {
    width: calc(100% - 15rem);
    margin-bottom: 1rem;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 

}
@media (min-width: 275px) { 
  .LocationDropdown {
    position: relative;
    float: right;
    height: 3.75rem;
    width: 100%;
    cursor: pointer;
    background-color: #fdfdfd;
    border: 1px solid #f0f0f0;
    border-top: none;
    margin-bottom: 1rem;
  }
  
  .LocationDropdown > .button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: #223254;
  }
  
  .LocationDropdown > .button span {
    position: absolute;
    left: 1rem;
    bottom: 0.875rem;
  }
  
  .LocationDropdown > .dropdown {
    display: none;
    position: absolute;
    top: calc(100% + 1px);
    right: -1px;
    max-height: 20rem;
    width: calc(100% + 2px);
    background-color: #fdfdfd;
    z-index: 1;
    border: 1px solid #f0f0f0;
    border-top: none;
    overflow: scroll;
  }
  
  .LocationDropdown > .dropdown.active {
    display: block;
  }
  
  .LocationDropdown > .dropdown ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .LocationDropdown > .dropdown ul li {
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
    padding: 0.5rem 1rem;
  }
  
  .LocationDropdown > .dropdown ul li:last-child {
    border: none;
  }
  
  .LocationDropdown > .dropdown ul li:hover {
    background-color: #f4f4f4;
  }
  
  .LocationDropdown .button {
    background-color: #fdfdfd;
    transition: background-color 200ms ease;
  }
  
  .LocationDropdown .button::after {
    content: '';
    position: absolute;
    right: 1rem;
    bottom: calc(50% - 0.5rem);
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    
    border-top: 0.75rem solid #9098A9;
    border-bottom: none;
  }
  
  .LocationDropdown .button.active {
    background-color: #f4f4f4;
  }
  
  .LocationDropdown .button.active::after {
    border-top: none;
    border-bottom: 0.75rem solid #9098A9;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .LocationDropdown {
    width: 15rem;
    border: 1px solid #f0f0f0;
    border-left: none;
    margin-bottom: 0;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 275px) { 
  #user_resources,
  #about_craigslist {
    font-size: 0.875rem;
    list-style: none;
    margin-bottom: 1rem;
    padding: 0;
  }
  
  #user_resources {
    margin-top: 2rem;
  }
  
  #user_resources li,
  #about_craigslist li {
    border: 1px solid #f0f0f0;
    text-align: center;
    background-color: #fdfdfd;
    transition: background-color 200ms ease;
  }
  
  #user_resources li:hover,
  #about_craigslist li:hover {
    background-color: #f4f4f4;
  }
  
  #user_resources li a,
  #about_craigslist li a {
    display: block;
    padding: 0.5rem 0;
  
    transition: color 200ms ease;
  }
  
  #user_resources li a:hover,
  #about_craigslist li a:hover {
    color: #551a8b;
    text-decoration: none;
  }
  
  #user_resources li:not(:last-child),
  #about_craigslist li:not(:last-child) {
    border-bottom: none;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  #user_resources,
  #about_craigslist {
    width: 20rem;
    font-size: 0.875rem;
    list-style: none;
    margin: 2rem auto 0;
    padding: 0;
  }
  
  #about_craigslist {
    margin: 2rem 0 2rem;
  }

  #user_resources {
    float: left;
  }

  #about_craigslist {
    float: right;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  #user_resources,
  #about_craigslist {
    float: none;
    width: auto;
    width: initial;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  
}
