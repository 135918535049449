/* // Small devices (landscape phones, 300px and up) */
@media (min-width: 300px) { 
  .accordion__panel {
    border-bottom: 1px solid #e7e7e7;
  }
  
  .accordion__panel__main {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #f7f7f7;
    color: #3b5998;
    cursor: pointer;
    padding: 1rem;
  
    transition: all 400ms ease;
  }
  
  .accordion__panel__main:hover {
    background-color: #3b5998;
    color: #ffffff;
    transform: translateY(-0.25rem);
  }
  
  .accordion__panel__main:active {
    background-color: #3b5998;
    color: #ffffff;
    transform: translateY(0);
  }
  
  .accordion__panel__main.active {
    background-color: #3b5998;
    color: #ffffff;
    transform: translateY(0);
  }
  
  .accordion__panel__main:only-child {
    pointer-events: none;
  }

  .accordion__panel__main:not(:only-child)::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;

    font-family: "Font Awesome 5 Free"; font-size: 1.25rem; font-weight: 900; content: "\f0fe";
  }

  .accordion__panel__main.active:not(:only-child)::after {
    content: "\f146";
  }
  
  .accordion__panel__main .accordion__panel__title {
    margin-right: 1rem;
    flex: 3 1 100%;
  }
  
  .accordion__panel__main .accordion__panel__company,
  .accordion__panel__main .accordion__panel__location,
  .accordion__panel__main .accordion__panel__duration {
    color: #8b9dc3;
  }
  
  .accordion__panel__main:hover .accordion__panel__company,
  .accordion__panel__main:hover .accordion__panel__location,
  .accordion__panel__main:hover .accordion__panel__duration,
  .accordion__panel__main.active .accordion__panel__company,
  .accordion__panel__main.active .accordion__panel__location,
  .accordion__panel__main.active .accordion__panel__duration {
    color: inherit;
  }
  
  .accordion__panel__main .accordion__panel__company {
    flex: 1 1 100%;
    margin-bottom: 1rem;
  }
  
  .accordion__panel__main .accordion__panel__location {
    flex: 1 1 50%;
    font-size: 0.875rem;
  }
  
  .accordion__panel__main .accordion__panel__location::before,
  .subaccordion__panel__main .subaccordion__panel__location::before {
    font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f3c5";
    margin-right: 0.5rem;
  }
  
  .accordion__panel__main .accordion__panel__duration {
    flex: 1 1 50%;
    text-align: right;
    font-size: 0.875rem;
  }
  
  .accordion__panel .accordion__panel__duration::before {
    font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f017";
    margin-right: 0.5rem;
  }
  
  .accordion__panel__body {
    background-color: #e7e7e7;
    transition: all 400ms ease;
  }
  
  .subaccordion {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .subaccordion__panel:last-of-type .subaccordion__panel__main {
    border-bottom: none;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .accordion__panel__main {
    padding: 1.5rem 2rem 1.5rem 2rem;
  }

  .accordion__panel__main .accordion__panel__title {
    margin-right: 0;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .accordion__panel__main .accordion__panel__title {
    margin: 0 0 .5rem;
  }

  .accordion__panel__main {
    padding: 1.5rem 4rem 1.5rem 4rem;
  }

  .accordion__panel__main:not(:only-child)::after {
    left: 0;
    right: initial;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  .accordion__panel__main {
    padding: 1.5rem 6rem 1.5rem 4rem;
  }

  .accordion__panel__main .accordion__panel__company {
    font-size: 1rem;
    line-height: 1.125rem;
    flex: 1 1 60%;
    margin-bottom: 0;
  }
  
  .accordion__panel__main .accordion__panel__location {
    font-size: 1rem;
    line-height: 1.125rem;
    flex: 1 1 20%;
    font-size: initial;
  }
  
  .accordion__panel__main .accordion__panel__duration {
    font-size: 1rem;
    line-height: 1.125rem;
    flex: 1 1 20%;
    font-size: initial;
  }
}