@media (min-width: 275px) { 
  .LocationDropdown {
    position: relative;
    float: right;
    height: 3.75rem;
    width: 100%;
    cursor: pointer;
    background-color: #fdfdfd;
    border: 1px solid #f0f0f0;
    border-top: none;
    margin-bottom: 1rem;
  }
  
  .LocationDropdown > .button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: #223254;
  }
  
  .LocationDropdown > .button span {
    position: absolute;
    left: 1rem;
    bottom: 0.875rem;
  }
  
  .LocationDropdown > .dropdown {
    display: none;
    position: absolute;
    top: calc(100% + 1px);
    right: -1px;
    max-height: 20rem;
    width: calc(100% + 2px);
    background-color: #fdfdfd;
    z-index: 1;
    border: 1px solid #f0f0f0;
    border-top: none;
    overflow: scroll;
  }
  
  .LocationDropdown > .dropdown.active {
    display: block;
  }
  
  .LocationDropdown > .dropdown ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .LocationDropdown > .dropdown ul li {
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
    padding: 0.5rem 1rem;
  }
  
  .LocationDropdown > .dropdown ul li:last-child {
    border: none;
  }
  
  .LocationDropdown > .dropdown ul li:hover {
    background-color: #f4f4f4;
  }
  
  .LocationDropdown .button {
    background-color: #fdfdfd;
    transition: background-color 200ms ease;
  }
  
  .LocationDropdown .button::after {
    content: '';
    position: absolute;
    right: 1rem;
    bottom: calc(50% - 0.5rem);
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    
    border-top: 0.75rem solid #9098A9;
    border-bottom: none;
  }
  
  .LocationDropdown .button.active {
    background-color: #f4f4f4;
  }
  
  .LocationDropdown .button.active::after {
    border-top: none;
    border-bottom: 0.75rem solid #9098A9;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .LocationDropdown {
    width: 15rem;
    border: 1px solid #f0f0f0;
    border-left: none;
    margin-bottom: 0;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  
}