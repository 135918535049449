/* // Small devices (landscape phones, 300px and up) */
@media (min-width: 300px) { 
  .App {
    position: relative;
  }
  
  .App .mobile-navigation-bar__section__icon--linkedin {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0077b5;
    border: 1px solid #0077b5;
    border-radius: 0.25rem;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    margin: 0 auto 0.5rem;
  
    box-shadow: 0 3px 0 rgba(0,119,181,0.6);
    transition: all 200ms ease;
    z-index: 2;
  }
  
  .App .mobile-navigation-bar__section__icon--linkedin:hover {
    transform: translateY(3px);
    box-shadow: 0 0 0 rgba(59,89,152,0.6);
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .App .mobile-navigation-bar__section__icon--linkedin { 
    display: none;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  
}